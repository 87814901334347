import axios from 'axios';

const getAssetsData = async (username, ual) => {
  try {
    if (username) {
      const config = await getConfig(ual);
      let user = await getUser(username, ual);
      let stakePower = 0;
      if (user.data.length > 0) {
        for (let i = 0; i < user.data[0].inventory.length; i++) {
          for (let j = 0; j < config.levels.length; j++) {
            if (user.data[0].inventory[i].key === config.levels[j].key) {
              stakePower +=
                user.data[0].inventory[i].value * config.levels[j].value;
            }
          }
        }
        user.last_claim = user.data[0].last_claim;
      } else user.last_claim = 0;
      user.stakePower = stakePower;
      const assets = await getAssets(username);
      const unstakedAssets = await getUnstakedAssets(
        assets.data.data,
        username,
        ual
      );

      return {
        stakedAssets: unstakedAssets.staked,
        unstakedAssets: unstakedAssets.unstaked,
        userData: user,
      };
    } else return;
  } catch (e) {
    return e;
  }
};

const getUser = async (username, ual) => {
  try {
    const r = await ual.activeUser.rpc.get_table_rows({
      json: true,
      code: 'crypliftrstk',
      scope: 'crypliftrstk',
      table: 'accounts',
      limit: 1,
      lower_bound: username,
    });
    let empty = {
      account: username,
      data: [],
    };
    if (r.rows.length === 0 || r.rows[0].account !== username) return empty;
    return r.rows[0];
  } catch (e) {
    return e;
  }
};

const getConfig = async (ual) => {
  try {
    const r = await ual.activeUser.rpc.get_table_rows({
      json: true,
      code: 'crypliftrstk',
      scope: 'crypliftrstk',
      table: 'pools',
      limit: 1,
    });

    return r.rows[0];
  } catch (e) {
    return e;
  }
};

const getAssets = async (username) => {
  const data = await axios.get(
    `${process.env.REACT_APP_ATOMIC_ASSETS_API}/atomicassets/v1/assets?collection_name=cryptolifter&owner=${username}&page=1&limit=1000&order=desc&sort=asset_id`
  );
  return data;
};

const getUnstakedAssets = async (assets, user, ual) => {
  const unstaked = [];
  const staked = [];

  const arr = Object.values(assets);
  const templateIds = await getTemplateIds(ual);
  for (const asset of arr) {
    if (templateIds.includes(parseInt(asset.template.template_id))) {
      const checkedAsset = await checkAsset(asset.asset_id, user, ual);
      if (checkedAsset) {
        staked.push(asset);
      } else unstaked.push(asset);
    }
  }

  return { unstaked, staked };
};

const checkAsset = async (assetId, user, ual) => {
  try {
    const r = await ual.activeUser.rpc.get_table_rows({
      json: true,
      code: 'crypliftrstk',
      scope: 'crypliftrstk',
      table: 'plates',
      limit: 1,
      lower_bound: assetId,
    });
    if (r.rows.length === 0) return false;
    if (r.rows[0].asset_id === assetId && r.rows[0].account === user) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    return e;
  }
};

const getTemplateIds = async (ual) => {
  try {
    const r = await ual.activeUser.rpc.get_table_rows({
      json: true,
      code: 'crypliftrstk',
      scope: 'crypliftrstk',
      table: 'rarity',
      limit: 1000,
    });

    const templateIds = [];
    r.rows.forEach((item) => {
      item.template_ids.forEach((id) => {
        templateIds.push(parseInt(id));
      });
    });
    return templateIds;
  } catch (e) {
    return e;
  }
};

export { getAssetsData };
