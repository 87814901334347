import styled from 'styled-components';

export const Title = styled.h1`
  font-family: Open Sans;
  font-weight: 700;
  font-size: 72px;
  line-height: 37.5px;
  text-align: center;
  color: #ffff;
  @media (max-width: 768px) {
    font-size: 32px;
    line-height: 27.5px;
  }
`;
