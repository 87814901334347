import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Footer from '../../shared/components/Footer/Footer';
import Navbar from '../../shared/components/Navbar/Navbar';
import Card from '../../shared/ui-library/Card/Card';
import { Title } from '../../shared/ui-library/Title/Title';
import { withUAL } from 'ual-reactjs-renderer';
import { getAssetsData } from '../../shared/helpers/assets';
import { Button } from '../../shared/ui-library/Button/Button';

const Container = styled.div`
  position: relative;
  width: 75%;
  margin: auto;
  padding: 146px 0px;
  @media (max-width: 768px) {
    padding: 106px 0px;
  }
`;

const Subtitle = styled.p`
  font-family: Open Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  color: #fff;
  text-align: center;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
`;

const InfoContainer = styled.div`
  padding: 40px 0;
  /* background: #fc475d; */
  border-radius: 8px;
  width: 350px;
  margin: 20px 10px;
  background: rgb(252, 71, 93);
  background: linear-gradient(
    90deg,
    rgba(252, 71, 93, 0.2) 0%,
    rgba(252, 71, 163, 0.2) 35%,
    rgba(184, 0, 255, 0.3) 100%
  );

  & h5 {
    font-family: Open Sans;
    font-size: 24px;
    font-weight: 600;
    color: #fff;
    text-align: center;
    padding: 0;
    margin: 0;
  }
  & p {
    font-family: Open Sans;
    font-size: 20px;
    font-weight: 400;
    color: #fff;
    text-align: center;
    padding: 0;
    margin: 0;
    margin-top: 20px;
  }
`;

const WeightPacks = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  // const [assets, setAssets] = useState([]);
  // const [timeLeft, setTime] = useState([]);
  const [stakePower, setPower] = useState([]);
  const [unclaimedBal, setUnclaimed] = useState([]);

  const [stakedAssets, setStakedAssets] = useState([]);

  const [unstakedAssets, setUnstakedAssets] = useState([]);

  const createAction = (contract, name, user, permission) => {
    return {
      actions: [
        {
          account: contract,
          name: name,
          authorization: [
            {
              actor: user,
              permission: permission,
            },
          ],
          data: {
            _user: user,
          },
        },
      ],
    };
  };

  async function claimGains() {
    try {
      const action = createAction(
        'crypliftrstk',
        'claim',
        props.ual.activeUser.accountName,
        'active'
      );
      await props.ual.activeUser.signTransaction(action, {
        expireSeconds: 120,
        blocksBehind: 3,
      });
      alert('Rewards Claimed Successfully !');
      window.location.reload(false);
    } catch (e) {
      alert(e);
    }
  }

  const getAssets = async () => {
    setIsLoading(true);
    try {
      const Assets = await getAssetsData(
        props.ual.activeUser.accountName,
        props.ual
      );
      // const now = new Date();
      // const utcMilllisecondsSinceEpoch =
      //   now.getTime() + now.getTimezoneOffset() * 60 * 1000;
      // const utcSecondsSinceEpoch = Math.round(
      //   utcMilllisecondsSinceEpoch / 1000
      // );

      // var timeLeft,
      var stakePower,
        unclaimed = 0;
      if (Assets.userData != null) {
        // var seconds = Assets.userData.last_claim + 3600 - utcSecondsSinceEpoch;
        // var g = new Date(seconds * 1000).toISOString().substr(14, 5);
        // timeLeft =
        //   utcSecondsSinceEpoch >= Assets.userData.last_claim + 3600
        //     ? 'Claim now'
        //     : g;
        stakePower = Assets.userData.stakePower;
        unclaimed = Assets.userData.data[0].unclaimed;
      }
      setIsLoading(false);
      // setTime(timeLeft);
      setPower(stakePower);
      setUnclaimed(unclaimed);
      // setAssets(Assets.stakedAssets);
      setStakedAssets(Assets.stakedAssets);
      setUnstakedAssets(Assets.unstakedAssets);
    } catch (e) {
      console.log(e);
      alert(e.message);
    }
  };
  useEffect(() => {
    if (props.ual.activeUser) {
      getAssets();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.ual]);

  return (
    <div>
      <Navbar />
      <Container>
        <Title>{isLoading ? 'Loading...' : 'Your NFT Inventory'}</Title>
        {!isLoading && (
          <Subtitle>
            {stakedAssets.length > 0 || unstakedAssets.length
              ? "All your purchased NFT's will appear here."
              : "You do not own any cryptolifter NFT's"}
          </Subtitle>
        )}
        {/* <Searchinput /> */}
        {!isLoading && (
          <>
            <div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '60px',
                }}
              >
                <Button onClick={claimGains}>Claim Gains</Button>
              </div>

              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <InfoContainer>
                  <h5>Rewards</h5>
                  <p>{stakePower} Gains / Hour</p>
                </InfoContainer>
                <InfoContainer>
                  <h5>Unclaimed Balance</h5>
                  <p>{unclaimedBal}</p>
                </InfoContainer>
              </div>
            </div>
            {unstakedAssets.length > 0 && (
              <>
                <Subtitle>Unstaked Assets</Subtitle>
                <CardContainer>
                  {unstakedAssets.map((item, idx) => {
                    if (item.template.immutable_data.img) {
                      return (
                        <Card
                          assetId={item.asset_id}
                          img={`https://ipfs.io/ipfs/${item.template.immutable_data.img}`}
                          url={`${process.env.REACT_APP_ATOMIC_HUB}/explorer/asset/${item.asset_id}`}
                          // isPrimary="true"
                          gain={true}
                          button="true"
                          cardwrapper={true}
                        />
                      );
                    } else return null;
                  })}
                </CardContainer>
              </>
            )}
            {stakedAssets.length > 0 && (
              <>
                <Subtitle>Staked Assets</Subtitle>
                <CardContainer>
                  {stakedAssets.map((item, idx) => {
                    if (item.template.immutable_data.img) {
                      return (
                        <Card
                          assetId={item.asset_id}
                          img={`https://ipfs.io/ipfs/${item.template.immutable_data.img}`}
                          url={`${process.env.REACT_APP_ATOMIC_HUB}/explorer/asset/${item.asset_id}`}
                          // isPrimary="true"
                          gain={true}
                          button="true"
                          cardwrapper={true}
                          isStaked
                        />
                      );
                    } else return null;
                  })}
                </CardContainer>
              </>
            )}
          </>
        )}
      </Container>
      <Footer />
    </div>
  );
};

export default withUAL(WeightPacks);
