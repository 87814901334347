import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import Footer from '../../shared/components/Footer/Footer';
import Navbar from '../../shared/components/Navbar/Navbar';
import Card from '../../shared/ui-library/Card/Card';
import { Title } from '../../shared/ui-library/Title/Title';

const Container = styled.div`
  position: relative;
  width: 75%;
  margin: auto;
  padding: 146px 0px;
  @media (max-width: 768px) {
    padding: 106px 0px;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
`;

const WeightPacks = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [assets, setAssets] = useState([]);

  const getAssets = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `https://wax.api.atomicassets.io/atomicassets/v1/assets?collection_name=cryptolifter&page=1&limit=10000&order=desc&sort=asset_id`
      );
      setAssets(response.data.data);
      setIsLoading(false);
    } catch (e) {
      //
    }
  };
  useEffect(() => {
    getAssets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Navbar />
      <Container>
        <Title style={{ marginBottom: '100px' }}>
          {isLoading ? "Loading All NFT's..." : "All NFT's"}
        </Title>
        {/* <Searchinput /> */}
        {!isLoading && (
          <CardContainer>
            {assets.map((item, idx) => {
              if (item.template.immutable_data.img) {
                return (
                  <Card
                    img={`https://ipfs.io/ipfs/${item.template.immutable_data.img}`}
                    url={`https://wax.atomichub.io/explorer/asset/${item.asset_id}`}
                    // isPrimary="true"
                    gain={true}
                    button="true"
                    cardwrapper={true}
                  />
                );
              } else return null;
            })}
          </CardContainer>
        )}
      </Container>
      <Footer />
    </div>
  );
};

export default WeightPacks;
