import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import { Wax } from '@eosdacio/ual-wax';
import { Anchor } from 'ual-anchor';
import dotenv from 'dotenv';
dotenv.config();

const myChain = {
  chainId: process.env.REACT_APP_WAX_CHAIN_ID,
  // rpcEndpoints: [{ protocol: 'https', host: 'chain.wax.io', port: '' }],
  // rpcEndpoints: [{ protocol: 'https', host: 'api.wax.alohaeos.com', port: '' }],
  // rpcEndpoints: [{ protocol: 'https', host: 'testnet.waxsweden.org', port: '' }],
  rpcEndpoints: [
    {
      protocol: 'https',
      host: process.env.REACT_APP_WAX_RPC_ENDPOINT,
      port: '',
    },
  ],
};

// const eos = {
//   chainId: '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4',
//   rpcEndpoints: [
//     {
//       protocol: 'https',
//       host: 'wax.eosphere.com',
//       port: '443',
//     },
//   ],
// };

const wax = new Wax([myChain], { appName: 'Crypto Lifters' });
const anchor = new Anchor([myChain], {
  appName: 'Crypto Lifters',
});

const MyUALConsumer = withUAL(App);

ReactDOM.render(
  <UALProvider
    chains={[myChain]}
    authenticators={[wax, anchor]}
    appName={'Crypto Lifters'}
  >
    <MyUALConsumer />
  </UALProvider>,
  document.getElementById('root')
);
